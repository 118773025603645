// const radConfig = {
//   allottement_status: false,
//   baseUrl: "http://localhost:8890/api/quickrad",
//    version: "1.2.06",
//   radTitle: "AIkenist Technology Pvt. Ltd.",
//   viewerURL: "http://localhost:3000/viewer",
//   viewer3DURL: "http://localhost:3000/viewer?StudyInstanceUIDs=",
//   thumbnailURL: "http://localhost:8042/instances/",
//   dicomWeb: "http://localhost:8042/dicom-web",
//   studyList: "http://localhost:8042/studies",
//   // client: "localhost",
//   client: "test",
//   localViewer: "http://localhost:3000/local",
//   paymentComplete: true,
// radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp"
// };

// ***************** GTR ********************
const radConfig = {
  allottement_status: false,
  currentUrl: "https://test.aikenist.com/",
  baseUrl: "https://test.aikenist.com/api/quickrad",
  baseUrlRadio: "https://test.aikenist.com/api/quickrad/radio",
  radTitle: "AIkenist Technology Pvt. Ltd.",
  viewerURL: "https://test.aikenist.com/aiken-viewer/viewer",
  viewer3DURL: "https://test.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
  viewerPET: "https://testv.aikenist.com/aiken-viewer/tmtv?StudyInstanceUIDs=",
  viewerMultiple: "https://testv.aikenist.com/aiken-viewer/viewer?",
  medDream: "https://testv.aikenist.com/?study=",
  thumbnailURL: "https://test.aikenist.com/dicom-web/instances/",
  dicomWeb: "https://test.aikenist.com/dicom-web",
  studyList: "https://test.aikenist.com/dicom-web/studies",
  localViewer: "https://test.aikenist.com/aiken-viewer/local",
  client: "test",
  paymentComplete: true,
  version: "1.2.06",
  radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp",
};

// ***************** RRS ********************
// const radConfig = {
//   allottement_status: false,
//   currentUrl: "https://rrs.aikenist.com/",
//   baseUrl: "https://rrs.aikenist.com/api/quickrad",
//   baseUrlRadio: "https://rrs.aikenist.com/api/quickrad/radio",
//   radTitle: "AIkenist Technology Pvt. Ltd.",
//   viewerURL: "https://rrs.aikenist.com/aiken-viewer/viewer",
//   // viewerURL: "https://rrsv.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewer3DURL:"https://rrsv.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewerPET:"https://rrsv.aikenist.com/aiken-viewer/tmtv?StudyInstanceUIDs=",
//   viewerMultiple: "https://rrsv.aikenist.com/aiken-viewer/viewer?",
//   medDream:"https://rrsv.aikenist.com/?study=",
//   thumbnailURL: "https://rrs.aikenist.com/dicom-web/instances/",
//   dicomWeb: "https://rrs.aikenist.com/dicom-web",
//   studyList: "https://rrs.aikenist.com/dicom-web/studies",
//   localViewer: "https://rrs.aikenist.com/aiken-viewer/local",
//   client: "rrs",
//   paymentComplete: true,
//   version: "1.2.06",
// radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp"
// };

// ***************** QR ********************
// const radConfig = {
//   allottement_status: false,
//   currentUrl: "https://qr.aikenist.com/",
//   baseUrl: "https://qr.aikenist.com/api/quickrad",
//   baseUrlRadio: "https://qr.aikenist.com/api/quickrad/radio",
//   radTitle: "AIkenist Technology Pvt. Ltd.",
//   viewerURL: "https://qr.aikenist.com/aiken-viewer/viewer",
//   // viewerURL: "https://qrv.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewer3DURL:"https://qrv.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewerPET:"https://qrv.aikenist.com/aiken-viewer/tmtv?StudyInstanceUIDs=",
//   viewerMultiple: "https://qrv.aikenist.com/aiken-viewer/viewer?",
//   medDream:"https://qrv.aikenist.com/?study=",
//   thumbnailURL: "https://qr.aikenist.com/dicom-web/instances/",
//   dicomWeb: "https://qr.aikenist.com/dicom-web",
//   studyList: "https://qr.aikenist.com/dicom-web/studies",
//   localViewer: "https://qr.aikenist.com/aiken-viewer/local",
//   client: "qr",
//   paymentComplete: true,
//   version: "1.2.06",
// radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp"
// };

// ***************** QR1 ********************
// const radConfig = {
//   allottement_status: false,
//   currentUrl: "https://qr1.aikenist.com/",
//   baseUrl: "https://qr1.aikenist.com/api/quickrad",
//   baseUrlRadio: "https://qr1.aikenist.com/api/quickrad/radio",
//   radTitle: "AIkenist Technology Pvt. Ltd.",
//   viewerURL: "https://qr1.aikenist.com/aiken-viewer/viewer",
//   // viewerURL: "https://qr1v.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewer3DURL:
//     "https://qr1v.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewerPET: "https://qr1v.aikenist.com/aiken-viewer/tmtv?StudyInstanceUIDs=",
//   viewerMultiple: "https://qr1v.aikenist.com/aiken-viewer/viewer?",
//   medDream: "https://qr1v.aikenist.com/?study=",
//   thumbnailURL: "https://qr1.aikenist.com/dicom-web/instances/",
//   dicomWeb: "https://qr1.aikenist.com/dicom-web",
//   studyList: "https://qr1.aikenist.com/dicom-web/studies",
//   localViewer: "https://qr1.aikenist.com/aiken-viewer/local",
//   client: "qr1",
//   paymentComplete: true,
//   version: "1.2.06",
//   radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp",
// };

// ***************** QR2 ********************
// const radConfig = {
//   allottement_status: false,
//   baseUrl: "https://qr2.aikenist.com/api/quickrad",
//   baseUrlRadio: "https://qr2.aikenist.com/api/quickrad/radio",
//    version: "1.2.06",
//   radTitle: "AIkenist Technology Pvt. Ltd.",
//   viewerURL: "https://qr2.aikenist.com/aiken-viewer/viewer",
//   viewer3DURL:"https://qr2.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   thumbnailURL: "https://qr2.aikenist.com/dicom-web/instances/",
//   dicomWeb: "https://qr2.aikenist.com/dicom-web",
//   studyList: "https://qr2.aikenist.com/dicom-web/studies",
//   localViewer: "https://qr2.aikenist.com/aiken-viewer/local",
//   client: "qr2",
//   paymentComplete: true,
// radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp"
// };

// ***************** QR3 ********************
// const radConfig = {
//   allottement_status: false,
//   baseUrl: "https://qr3.aikenist.com/api/quickrad",
//   baseUrlRadio: "https://qr3.aikenist.com/api/quickrad/radio",
//   version: "1.2.06",
//   radTitle: "AIkenist Technology Pvt. Ltd.",
//   // viewerURL: "https://qr3.aikenist.com/aiken-viewer/viewer",
//   viewerURL: "https://qr3.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewer3DURL: "https://qr3.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   thumbnailURL: "https://qr3.aikenist.com/dicom-web/instances/",
//   dicomWeb: "https://qr3.aikenist.com/dicom-web",
//   studyList: "https://qr3.aikenist.com/dicom-web/studies",
//   localViewer: "https://qr3.aikenist.com/aiken-viewer/local",
//   client: "qr3",
//   paymentComplete: true,
// radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp"
// };

// ***************** QR4 ********************
// const radConfig = {
//   allottement_status: false,
//   currentUrl: "https://qr4.aikenist.com/",
//   baseUrl: "https://qr4.aikenist.com/api/quickrad",
//   baseUrlRadio: "https://qr4.aikenist.com/api/quickrad/radio",
//   radTitle: "AIkenist Technology Pvt. Ltd.",
//   viewerURL: "https://qr4.aikenist.com/aiken-viewer/viewer",
//   // viewerURL: "https://qr4v.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewer3DURL:"https://qr4v.aikenist.com/aiken-viewer/viewer?StudyInstanceUIDs=",
//   viewerPET:"https://qr4v.aikenist.com/aiken-viewer/tmtv?StudyInstanceUIDs=",
//   viewerMultiple: "https://qr4v.aikenist.com/aiken-viewer/viewer?",
//   medDream:"https://qr4v.aikenist.com/?study=",
//   thumbnailURL: "https://qr4.aikenist.com/dicom-web/instances/",
//   dicomWeb: "https://qr4.aikenist.com/dicom-web",
//   studyList: "https://qr4.aikenist.com/dicom-web/studies",
//   localViewer: "https://qr4.aikenist.com/aiken-viewer/local",
//   client: "qr4",
//   paymentComplete: true,
//   version: "1.2.06",
// radiAntPath: "C:\\xampp\\htdocs\\python\\input_scan\\tmp"
// };

// ******************************************

localStorage.setItem("client", radConfig.client);

if (
  localStorage.getItem("showTAT") == "" ||
  localStorage.getItem("showTAT") == null
) {
  localStorage.setItem("showTAT", "false");
}
if (
  localStorage.getItem("studyTracker") == "" ||
  localStorage.getItem("studyTracker") == null
) {
  localStorage.setItem("studyTracker", "false");
}

export default radConfig;
